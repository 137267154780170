import { useApolloClient } from "@apollo/client";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./App.css";
import { createHashRouter, redirect, RouterProvider } from "react-router-dom";
import Loading from "./components/loading";
import {
  ChangePasswordPage,
  HomePage,
  LoginPage,
  MainLayout,
} from "./pages/lazy";
import {
  ChartOfAccounts,
  ManualJournals,
  ManualJournalsEdit,
  ManualJournalsNew,
  TransactionLocking,
} from "./pages/accountant/lazy";
import {
  InventoryAdjustments,
  InventoryAdjustmentsEdit,
  InventoryAdjustmentsNew,
  OpeningStock,
  ProductCategories,
  ProductGroups,
  ProductGroupsEdit,
  ProductGroupsNew,
  Products,
  ProductsEdit,
  ProductsNew,
  ProductUnits,
  TransferOrders,
  TransferOrdersEdit,
  TransferOrdersNew,
} from "./pages/products/lazy";
import {
  Bills,
  BillsEdit,
  BillsNew,
  Expenses,
  ExpensesEdit,
  ExpensesNew,
  PaymentsMade,
  PaymentsMadeNew,
  PurchaseOrders,
  PurchaseOrdersEdit,
  PurchaseOrdersNew,
  SupplierCredits,
  SupplierCreditsEdit,
  SupplierCreditsNew,
  Suppliers,
  SuppliersEdit,
  SuppliersNew,
  PaymentsMadeEdit,
} from "./pages/purchases/lazy";
import {
  AccountTransactions,
  AccountTypeSummary,
  APAgingDetails,
  APAgingSummary,
  ARAgingDetails,
  ARAgingSummary,
  BalanceSheet,
  BillsDetails,
  CashFlowReport,
  CreditNoteDetails,
  CustomerBalanceDetails,
  CustomerBalanceSummary,
  CustomerRefundHistory,
  DetailedGeneralLedger,
  ExpenseByCategory,
  ExpenseDetails,
  ExpenseDetailsByCategory,
  GeneralLedger,
  InventorySummary,
  InventoryValuationByItem,
  InventoryValuationSummary,
  InvoiceDetails,
  JournalReport,
  MovementOfEquity,
  PayableDetails,
  PayableSummary,
  PaymentsMadeReport,
  PaymentsReceivedReport,
  ProductSalesReport,
  ProfitAndLoss,
  PurchaseByProduct,
  PurchaseBySupplier,
  PurchaseOrderDetails,
  RealizedGainOrLoss,
  ReceivableDetails,
  ReceivableSummary,
  Reports,
  SalesByCustomer,
  SalesByProduct,
  SalesBySalesPerson,
  SalesOrderDetails,
  StockSummaryReport,
  SupplierBalanceDetails,
  SupplierBalanceSummary,
  SupplierCreditDetails,
  SupplierRefundHistory,
  TrialBalance,
  UnrealizedGainOrLoss,
  WarehouseReport,
} from "./pages/reports/lazy";
import {
  CreditNotes,
  CreditNotesEdit,
  CreditNotesNew,
  Customers,
  CustomersEdit,
  CustomersNew,
  Invoices,
  InvoicesEdit,
  InvoicesNew,
  PaymentsReceived,
  SalesOrders,
  SalesOrdersEdit,
  SalesOrdersNew,
  PaymentsReceivedEdit,
  PaymentsReceivedNew,
} from "./pages/sales/lazy";
import {
  Branches,
  Currencies,
  DeliveryMethods,
  OpeningBalanceAccDetails,
  OpeningBalances,
  OpeningBalancesEdit,
  PaymentModes,
  Profile,
  Reasons,
  Roles,
  RolesEdit,
  RolesNew,
  SalesPersons,
  ShipmentPreferences,
  TransactionNumberSeries,
  Users,
  Warehouses,
  TaxRates,
} from "./pages/settings/lazy";

// import TaxRates from "./pages/settings/TaxRates";
// import TaxSettings from "./pages/settings/TaxSettings";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { AllTransactions, Banking } from "./pages/banking/lazy";
// import PaymentsMadeEdit from "./pages/purchases/PaymentsMadeEdit";
// import PaymentsReceivedEdit from "./pages/sales/PaymentsReceivedEdit";
// import PaymentsReceivedNew from "./pages/sales/PaymentsReceivedNew";
import LocalStorageService from "./services/local_storage";
import { fetchModulesFromLocalStorage } from "./utils/HelperFunctions";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

function IsLoggedIn() {
  return !!LocalStorageService.getToken();
}

const routes = [
  {
    index: true,
    loader: protectedLoader,
    Component: HomePage,
  },
  {
    moduleName: "Product",
    path: "Products",
    Component: Products,
  },
  { moduleName: "Product", path: "products/new", Component: ProductsNew },
  { moduleName: "Product", path: "products/edit", Component: ProductsEdit },
  {
    moduleName: "InventoryAdjustment",
    path: "inventoryAdjustments",
    Component: InventoryAdjustments,
  },
  {
    moduleName: "InventoryAdjustment",
    path: "inventoryAdjustments/new",
    Component: InventoryAdjustmentsNew,
  },
  {
    moduleName: "InventoryAdjustment",
    path: "inventoryAdjustments/edit",
    Component: InventoryAdjustmentsEdit,
  },
  {
    moduleName: "ProductGroup",
    path: "productGroups",
    Component: ProductGroups,
  },
  {
    moduleName: "ProductGroup",
    path: "productGroups/new",
    Component: ProductGroupsNew,
  },
  {
    moduleName: "ProductGroup",
    path: "productGroups/edit",
    Component: ProductGroupsEdit,
  },
  {
    path: "openingStock",
    Component: OpeningStock,
  },
  {
    moduleName: "TransferOrder",
    path: "transferOrders/new",
    Component: TransferOrdersNew,
  },
  {
    moduleName: "TransferOrder",
    path: "transferOrders/edit",
    Component: TransferOrdersEdit,
  },
  {
    moduleName: "TransferOrder",
    path: "transferOrders",
    Component: TransferOrders,
  },
  {
    moduleName: "ProductCategory",
    path: "productCategories",
    Component: ProductCategories,
  },
  {
    moduleName: "ProductUnit",
    path: "productUnits",
    Component: ProductUnits,
  },
  { moduleName: "BankingAccount", path: "banking", Component: Banking },
  {
    moduleName: "BankingAccount",
    path: "banking/allTransactions",
    Component: AllTransactions,
  },
  // Sales
  { moduleName: "Customer", path: "customers", Component: Customers },
  {
    moduleName: "Customer",
    path: "customers/new",
    Component: CustomersNew,
  },
  {
    moduleName: "Customer",
    path: "customers/edit",
    Component: CustomersEdit,
  },
  { moduleName: "SalesOrder", path: "salesOrders", Component: SalesOrders },
  {
    moduleName: "SalesOrder",
    path: "salesOrders/new",
    Component: SalesOrdersNew,
  },
  {
    moduleName: "SalesOrder",
    path: "salesOrders/edit",
    Component: SalesOrdersEdit,
  },
  {
    moduleName: "CustomerPayment",
    path: "paymentsReceived",
    Component: PaymentsReceived,
  },
  {
    moduleName: "CustomerPayment",
    path: "paymentsReceived/new",
    Component: PaymentsReceivedNew,
  },
  {
    moduleName: "CustomerPayment",
    path: "paymentsReceived/edit",
    Component: PaymentsReceivedEdit,
  },
  { moduleName: "SalesInvoice", path: "invoices", Component: Invoices },
  {
    moduleName: "SalesInvoice",
    path: "invoices/new",
    Component: InvoicesNew,
  },
  {
    moduleName: "SalesInvoice",
    path: "invoices/edit",
    Component: InvoicesEdit,
  },
  { moduleName: "CreditNote", path: "creditNotes", Component: CreditNotes },
  {
    moduleName: "CreditNote",
    path: "creditNotes/new",
    Component: CreditNotesNew,
  },
  {
    moduleName: "CreditNote",
    path: "creditNotes/edit",
    Component: CreditNotesEdit,
  },
  //Purchases
  { moduleName: "Supplier", path: "suppliers", Component: Suppliers },
  {
    moduleName: "Supplier",
    path: "suppliers/new",
    Component: SuppliersNew,
  },
  {
    moduleName: "Supplier",
    path: "suppliers/edit",
    Component: SuppliersEdit,
  },
  { moduleName: "Expense", path: "expenses", Component: Expenses },
  { moduleName: "Expense", path: "expenses/new", Component: ExpensesNew },
  { moduleName: "Expense", path: "expenses/edit", Component: ExpensesEdit },
  {
    moduleName: "PurchaseOrder",
    path: "purchaseOrders",
    Component: PurchaseOrders,
  },
  {
    moduleName: "PurchaseOrder",
    path: "purchaseOrders/new",
    Component: PurchaseOrdersNew,
  },
  {
    moduleName: "PurchaseOrder",
    path: "purchaseOrders/edit",
    Component: PurchaseOrdersEdit,
  },
  { moduleName: "Bill", path: "bills", Component: Bills },
  { moduleName: "Bill", path: "bills/new", Component: BillsNew },
  { moduleName: "Bill", path: "bills/edit", Component: BillsEdit },
  {
    moduleName: "SupplierPayment",
    path: "paymentsMade",
    Component: PaymentsMade,
  },
  {
    moduleName: "SupplierPayment",
    path: "paymentsMade/new",
    Component: PaymentsMadeNew,
  },
  {
    moduleName: "SupplierPayment",
    path: "paymentsMade/edit",
    Component: PaymentsMadeEdit,
  },
  {
    moduleName: "SupplierCredit",
    path: "supplierCredits",
    Component: SupplierCredits,
  },
  {
    moduleName: "SupplierCredit",
    path: "supplierCredits/new",
    Component: SupplierCreditsNew,
  },
  {
    moduleName: "SupplierCredit",
    path: "supplierCredits/edit",
    Component: SupplierCreditsEdit,
  },

  //Accountant
  {
    moduleName: "Journal",
    path: "manualJournals",
    Component: ManualJournals,
  },
  {
    moduleName: "Journal",
    path: "manualJournals/new",
    Component: ManualJournalsNew,
  },
  {
    moduleName: "Journal",
    path: "manualJournals/edit",
    Component: ManualJournalsEdit,
  },
  {
    moduleName: "Account",
    path: "chartOfAccounts",
    Component: ChartOfAccounts,
  },
  {
    path: "transactionLocking",
    Component: TransactionLocking,
  },
  //Reports
  {
    path: "reports",
    Component: Reports,
  },
  {
    moduleName: "BalanceSheetReport",
    path: "reports/balanceSheet",
    Component: BalanceSheet,
  },
  {
    moduleName: "ProfitAndLossReport",
    path: "reports/profitAndLoss",
    Component: ProfitAndLoss,
  },
  {
    moduleName: "JournalReport",
    path: "reports/journalReport",
    Component: JournalReport,
  },
  {
    moduleName: "AccountTransactionReport",
    path: "reports/accountTransactions",
    Component: AccountTransactions,
  },
  {
    moduleName: "AccountTypeSummaryReport",
    path: "reports/accountTypeSummary",
    Component: AccountTypeSummary,
  },
  {
    moduleName: "GeneralLedgerReport",
    path: "reports/generalLedger",
    Component: GeneralLedger,
  },
  {
    moduleName: "DetailedGeneralLedgerReport",
    path: "reports/detailedGeneralLedger",
    Component: DetailedGeneralLedger,
  },
  {
    moduleName: "TrialBalanceReport",
    path: "reports/trialBalance",
    Component: TrialBalance,
  },
  {
    moduleName: "SalesByCustomerReport",
    path: "reports/salesByCustomer",
    Component: SalesByCustomer,
  },
  {
    moduleName: "SalesBySalesPersonReport",
    path: "reports/salesBySalesPerson",
    Component: SalesBySalesPerson,
  },
  {
    moduleName: "SalesByProductReport",
    path: "reports/salesByProduct",
    Component: SalesByProduct,
  },
  {
    moduleName: "ProductSalesReport",
    path: "reports/productSalesReport",
    Component: ProductSalesReport,
  },
  {
    moduleName: "InventorySummaryReport",
    path: "reports/inventorySummary",
    Component: InventorySummary,
  },
  {
    moduleName: "InventoryValuationSummaryReport",
    path: "reports/inventoryValuationSummary",
    Component: InventoryValuationSummary,
  },
  {
    path: "reports/inventoryValuationByItem",
    Component: InventoryValuationByItem,
  },
  {
    moduleName: "StockSummaryReport",
    path: "reports/stockSummaryReport",
    Component: StockSummaryReport,
  },
  {
    moduleName: "CashFlowReport",
    path: "reports/cashFlowReport",
    Component: CashFlowReport,
  },
  {
    moduleName: "MovementOfEquityReport",
    path: "reports/movementOfEquity",
    Component: MovementOfEquity,
  },
  {
    moduleName: "APAgingSummaryReport",
    path: "reports/apAgingSummary",
    Component: APAgingSummary,
  },
  {
    moduleName: "PayableSummaryReport",
    path: "reports/payableSummary",
    Component: PayableSummary,
  },
  {
    moduleName: "PayableDetailReport",
    path: "reports/payableDetails",
    Component: PayableDetails,
  },
  {
    moduleName: "ReceivableSummaryReport",
    path: "reports/receivableSummary",
    Component: ReceivableSummary,
  },
  {
    moduleName: "ReceivableDetailReport",
    path: "reports/receivableDetails",
    Component: ReceivableDetails,
  },
  {
    moduleName: "PurchaseOrderDetailReport",
    path: "reports/purchaseOrderDetails",
    Component: PurchaseOrderDetails,
  },
  {
    moduleName: "SupplierBalancesReport",
    path: "reports/supplierBalanceDetails",
    Component: SupplierBalanceDetails,
  },
  {
    moduleName: "SupplierBalanceSummaryReport",
    path: "reports/supplierBalanceSummary",
    Component: SupplierBalanceSummary,
  },
  {
    moduleName: "SalesInvoiceDetailReport",
    path: "reports/invoiceDetails",
    Component: InvoiceDetails,
  },
  {
    moduleName: "SalesOrderDetailReport",
    path: "reports/salesOrderDetails",
    Component: SalesOrderDetails,
  },
  {
    moduleName: "CustomerBalancesReport",
    path: "reports/customerBalanceDetails",
    Component: CustomerBalanceDetails,
  },
  {
    moduleName: "CustomerBalanceSummaryReport",
    path: "reports/customerBalanceSummary",
    Component: CustomerBalanceSummary,
  },
  {
    moduleName: "ARAgingSummaryReport",
    path: "reports/arAgingSummary",
    Component: ARAgingSummary,
  },
  {
    moduleName: "ARAgingDetailReport",
    path: "reports/arAgingDetails",
    Component: ARAgingDetails,
  },
  {
    moduleName: "APAgingDetailReport",
    path: "reports/apAgingDetails",
    Component: APAgingDetails,
  },
  {
    moduleName: "BillDetailReport",
    path: "reports/billsDetails",
    Component: BillsDetails,
  },
  {
    moduleName: "ExpenseDetailReport",
    path: "reports/expenseDetails",
    Component: ExpenseDetails,
  },
  {
    moduleName: "ExpenseByCategory",
    path: "reports/expenseByCategory",
    Component: ExpenseByCategory,
  },
  {
    moduleName: "ExpenseDetailReport",
    path: "reports/expenseDetailsByCategory",
    Component: ExpenseDetailsByCategory,
  },
  {
    moduleName: "WarehouseInventoryReport",
    path: "reports/warehouseReport",
    Component: WarehouseReport,
  },
  {
    moduleName: "PaymentsReceived",
    path: "reports/paymentsReceivedReport",
    Component: PaymentsReceivedReport,
  },
  {
    moduleName: "PaymentsMade",
    path: "reports/paymentsMadeReport",
    Component: PaymentsMadeReport,
  },
  {
    moduleName: "CustomerRefundHistoryReport",
    path: "reports/customerRefundHistory",
    Component: CustomerRefundHistory,
  },
  {
    moduleName: "SupplierRefundHistoryReport",
    path: "reports/supplierRefundHistory",
    Component: SupplierRefundHistory,
  },
  {
    moduleName: "PurchasesBySupplierReport",
    path: "reports/purchaseBySupplier",
    Component: PurchaseBySupplier,
  },
  {
    moduleName: "PurchasesByProductReport",
    path: "reports/purchaseByProduct",
    Component: PurchaseByProduct,
  },
  {
    moduleName: "CreditNoteDetailsReport",
    path: "reports/creditNoteDetails",
    Component: CreditNoteDetails,
  },
  {
    moduleName: "SupplierCreditDetailReport",
    path: "reports/supplierCreditDetails",
    Component: SupplierCreditDetails,
  },
  {
    moduleName: "RealisedExchangeGainLossReport",
    path: "reports/realizedGainOrLoss",
    Component: RealizedGainOrLoss,
  },
  {
    moduleName: "UnrealisedExchangeGainLossReport",
    path: "reports/unrealizedGainOrLoss",
    Component: UnrealizedGainOrLoss,
  },
  //Settings
  { moduleName: "Business", path: "profile", Component: Profile },
  { moduleName: "Warehouse", path: "warehouses", Component: Warehouses },
  { moduleName: "Branch", path: "branch", Component: Branches },
  { moduleName: "Currency", path: "currencies", Component: Currencies },
  {
    path: "openingBalances",
    Component: OpeningBalances,
  },
  {
    moduleName: "OpeningBalance",
    path: "openingBalances/edit",
    Component: OpeningBalancesEdit,
  },
  {
    moduleName: "OpeningBalance",
    path: "openingBalances/account/details",
    Component: OpeningBalanceAccDetails,
  },
  {
    moduleName: "TransactionNumberSeries",
    path: "transactionNumberSeries",
    Component: TransactionNumberSeries,
  },
  { moduleName: "Tax&TaxGroup", path: "taxes", Component: TaxRates },
  { moduleName: "UserAccount", path: "users", Component: Users },
  { moduleName: "Role", path: "roles", Component: Roles },
  { moduleName: "Role", path: "roles/new", Component: RolesNew },
  { moduleName: "Role", path: "roles/Edit", Component: RolesEdit },
  {
    moduleName: "ShipmentPreference",
    path: "shipmentPreferences",
    Component: ShipmentPreferences,
  },
  {
    moduleName: "PaymentMode",
    path: "paymentModes",
    Component: PaymentModes,
  },
  {
    moduleName: "DeliveryMethod",
    path: "deliveryMethods",
    Component: DeliveryMethods,
  },
  { moduleName: "Reason", path: "reasons", Component: Reasons },
  {
    moduleName: "SalesPerson",
    path: "salesPersons",
    Component: SalesPersons,
  },
];

function loginLoader() {
  if (IsLoggedIn()) {
    return redirect("/");
  }
  return null;
}

function logoutLoader() {
  localStorage.clear();
  return redirect("/");
}

function protectedLoader({ request }) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!IsLoggedIn()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}

export default function App() {
  const client = useApolloClient(); // Access Apollo Client
  const [cacheCleared, setCacheCleared] = useState(false); // State to track if cache is cleared
  const modules = fetchModulesFromLocalStorage();
  const [isLoggedIn, setIsLoggedIn] = useState(IsLoggedIn);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const isModuleActionAllowed = useCallback(
    (moduleName, action) => {
      if (!isLoggedIn) {
        return false;
      }

      const moduleNames = moduleName.split("&");
      return moduleNames.some((moduleName) => {
        const module = modules.find((mod) => mod.moduleName === moduleName);
        return module && module.allowedActions.split(";").includes(action);
      });
    },
    [modules, isLoggedIn]
  );

  const isModuleReadAllowed = useCallback(
    (moduleName) => {
      return isModuleActionAllowed(moduleName, "read");
    },
    [isModuleActionAllowed]
  );

  const isModuleCreateAllowed = useCallback(
    (moduleName) => {
      return isModuleActionAllowed(moduleName, "create");
    },
    [isModuleActionAllowed]
  );

  const isModuleUpdateAllowed = useCallback(
    (moduleName) => {
      return isModuleActionAllowed(moduleName, "update");
    },
    [isModuleActionAllowed]
  );

  // Function to dynamically filter routes
  const getFilteredRoutes = useCallback(
    (routes) => {
      return routes.filter((route) => {
        const { path, moduleName } = route;

        if (!moduleName) return true;

        if (path.includes("/new")) {
          return isModuleCreateAllowed(moduleName);
        }

        if (
          path.includes("/edit") ||
          path.includes("transactionLocking") ||
          path.includes("openingBalances")
        ) {
          return isModuleUpdateAllowed(moduleName);
        }

        return isModuleReadAllowed(moduleName);
      });
    },
    [isModuleCreateAllowed, isModuleReadAllowed, isModuleUpdateAllowed]
  );

  const filteredRoutes = useMemo(
    () => getFilteredRoutes(routes),
    [getFilteredRoutes]
  );

  const router = createHashRouter([
    {
      id: "root",
      path: "/",
      loader: protectedLoader,
      Component: MainLayout,
      children: filteredRoutes,
    },
    {
      path: "changePassword",
      Component: ChangePasswordPage,
    },
    {
      path: "login",
      loader: loginLoader,
      Component: () => <LoginPage onLogin={handleLogin} />,
    },
    {
      path: "/logout",
      loader: logoutLoader,
    },
  ]);

  useEffect(() => {
    const clearApolloCache = async () => {
      try {
        await client.clearStore(); // Clear Apollo Client cache
        // console.log("Apollo Client cache cleared.");
      } catch (error) {
        console.error("Failed to clear Apollo Client cache:", error);
      } finally {
        setCacheCleared(true); // Set state to true once cache clearing is done
      }
    };

    clearApolloCache(); // Call the function to clear the cache
  }, [client]);

  // Prevent rendering until the cache is cleared
  if (!cacheCleared) {
    return null; // Render nothing or a loading spinner while cache is being cleared
  }

  return (
    <Suspense fallback={<Loading />}>
      {" "}
      <RouterProvider router={router} />
    </Suspense>
  );
}
